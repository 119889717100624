import NodeRuleConditions from '@Shared.Angular/@types/core/contracts/constants/nodeRuleConditions';
import * as angular from 'angular';

export enum StepTypeName {
  TASK = 'Task',
  APPROVAL = 'Approval',
  PARALLEL_APPROVAL = 'Parallel Approval',
  SEQUENTIAL_APPROVAL = 'Sequential Approval',
  CUSTOM_EMAIL = 'Custom Email',
  PUBLIC_FORM = 'Public Form',
  COMPONENT = 'Component',
  EXTERNAL_USER_TASK = 'External User Task'
}

export enum TaskType { // this is also Node.StepType
  TASK = 0,
  CUSTOM_EMAIL = 6,
  APPROVAL = 8,
  PARALLEL_APPROVAL = 9,
  SEQUENTIAL_APPROVAL = 10,
  COMPONENT = 11,
  EXTERNAL_USER_TASK = 12,
  PUBLIC_FORM = 99
}

export enum NodeCategory {
  ACTIVITY = 'activity',
  EXCLUSIVE_GATEWAY = 'exclusiveGateway',
  DIVERGE_GATEWAY = 'divergeGateway',
  CONVERGE_GATEWAY = 'convergeGateway',
  COMPONENT = 'component',
  EVENT = 'event',
  POOL = 'Pool',
  LANE = 'Lane',
  LINK = 'link'
}

export enum ApprovalTaskOptions {
  REJECT_AND_COMPLETE = '0',
  APPROVE = '1',
  REJECT_AND_GO_BACK = '2',
  REJECT_AND_GO_BACK_TO_FIRST_TASK = '3'
}

export enum FormFieldType {
  INSTRUCTION = 'instruction',

  TEXT = 'text',
  SHORT_TEXT = TEXT, // alias

  TEXTAREA = 'textarea',
  EMAIL = 'email',
  PASSWORD = 'password',
  TABLE = 'table',
  DATE = 'date',
  DATETIME = 'datetime',
  SIGNATURE = 'signature',
  NUMBER = 'number',
  CHECKBOX = 'checkbox',
  CURRENCY = 'currency',
  FILE_UPLOAD = 'fileupload',
  TASK_LIST = 'tasklist',
  MULTISELECT_LIST = 'multiselectlist',
  APPROVAL_RULE = 'approvalrule',

  RADIO_BUTTON_LIST = 'radiobuttonlist', // Option list
  OPTIONLIST = RADIO_BUTTON_LIST, // alias

  SELECT_LIST = 'selectlist', // Dropdown list
  DROPDOWN_LIST = SELECT_LIST, // alias
  LOOKUP = 'lookup',

  FORMULA = 'formula',
  APPROVAL_COMMENT = 'approvalcomment',
  ATTACH_DOCUMENT = 'attachdocument',
  IMAGE = 'image',
  RECAPTCHA = 'recaptcha'
}
export enum FormFieldTypePascal {
  INSTRUCTION = 'Instruction',

  TEXT = 'Text',
  SHORT_TEXT = TEXT, // alias

  TEXTAREA = 'Textarea',
  EMAIL = 'Email',
  PASSWORD = 'Password',
  TABLE = 'Table',
  DATE = 'Date',
  DATETIME = 'Datetime',
  SIGNATURE = 'Signature',
  NUMBER = 'Number',
  CHECKBOX = 'Checkbox',
  CURRENCY = 'Currency',
  FILE_UPLOAD = 'Fileupload',
  TASK_LIST = 'Tasklist',
  MULTISELECT_LIST = 'MultiSelectList',
  APPROVAL_RULE = 'ApprovalRule',

  RADIO_BUTTON_LIST = 'RadiobuttonList', // Option list
  OPTIONLIST = RADIO_BUTTON_LIST, // alias

  SELECT_LIST = 'Selectlist', // Dropdown list
  DROPDOWN_LIST = SELECT_LIST, // alias
  LOOKUP = 'LookUp',

  FORMULA = 'Formula',
  APPROVAL_COMMENT = 'ApprovalComment',
  ATTACH_DOCUMENT = 'AttachDocument',
  IMAGE = 'Image'
}

export enum FormFieldTypeEnum {
  APPROVAL_COMMENT = 0,
  APPROVAL_RULE = 1,
  BUTTON = 2,
  CHECKBOX = 3,
  CURRENCY = 4,
  EMAIL = 5,
  FILE_UPLOAD = 6,
  INSTRUCTION = 7,
  TABLE = 8,
  TEXT = 9,
  TEXT_AREA = 10,
  TEXT_BOX = 11,
  NUMBER = 12,
  PASSWORD = 13,
  RADIO_BUTTON_LIST = 14,
  SELECT_LIST = 15,
  SUBMIT = 16,
  TASK_LIST = 17,
  MULTISELECT_LIST = 18,
  DATE = 19,
  DATETIME = 20,
  SIGNATURE = 21,
  DYNAMIC_ACTORS = 22,
  LOOKUP = 23,
  FORMULA = 24,
  ATTACH_DOCUMENT = 25,
  IMAGE = 26
}

export enum NumberOfApproversRequiredType {
  ALL = 0,
  SUBSET = 1
}

export enum WhenApproversSelected {
  RUN_TIME = 0,
  MODEL_TIME = 1
}

export enum FlowinglyRoles {
  BUSINESS_ADMINISTRATOR = 'Business Administrator',
  FLOWINGLY_ADMINISTRATOR = 'Flowingly Administrator',
  WORKFLOW_USER = 'Workflow User',
  FLOW_MODEL_ADMINISTRATOR = 'Flow Model Administrator',
  REPORT_ADMINISTRATOR = 'Report Administrator'
}

export enum FlowinglyPermissions {
  ADMIN_ACCESS = 'admin.access',
  ADMIN_ACTIONS = 'admin.actions',
  ADMIN_ACTIONS_EMAIL = 'admin.actions.email',
  ADMIN_ACTIONS_BUSINESS_SETTING = 'admin.actions.businesssetting',
  ADMIN_ACTIONS_BUSINESS_SETTING_SELF = 'admin.actions.businesssetting.self',
  ADMIN_ACTIONS_APPLICATION_SETTING = 'admin.actions.applicationsetting',
  ADMIN_ACTIONS_FLOW = 'admin.actions.flow',
  ADMIN_ACTIONS_USER = 'admin.actions.user',
  ADMIN_ACTIONS_BUSINESS = 'admin.actions.business',
  BUSINESS_CLONE = 'business.clone',
  BUSINESS_SIGNUP = 'business.signup',
  FLOW_ALL_ACCESS = 'flow.all.access',
  FLOW_CANCEL = 'flow.cancel',
  FLOW_DELETE = 'flow.delete',
  FLOW_STARTONBEHALF = 'flow.startonbehalf',
  FLOWMODEL_BULKIMPORT = 'flowmodel.bulkimport',
  FLOWMODEL_CREATE = 'flowmodel.create',
  FLOWMODEL_UPDATE = 'flowmodel.update',
  FLOWMODEL_DELETE = 'flowmodel.delete',
  FLOWMODEL_PUBLISH = 'flowmodel.publish',
  FLOWMODEL_UPDATE_OWN = 'flowmodel.update.own',
  FLOWMODEL_DELETE_OWN = 'flowmodel.delete.own',
  FLOWMODEL_PUBLISH_OWN = 'flowmodel.publish.own',
  FLOWMODEL_OWNER_CANDIDATE = 'flowmodel.owner.candidate',
  FLOWMODEL_OWNER_UPDATE = 'flowmodel.owner.update',
  FLOWMODEL_REPORTOWNER_CANDIDATE = 'flowmodel.reportowner.candidate',
  FLOWMODEL_REPORTOWNER_UPDATE = 'flowmodel.reportowner.update',
  FLOW_NONCONFIDENTIAL_ACCESS = 'flow.nonconfidential.access',
  FLOW_START = 'flow.start',
  FLOW_STEP_REASSIGN = 'flow.step.reassign',
  FLOW_STEPTASK_CANCEL = 'flow.steptask.cancel',
  FLOW_STEPTASK_REASSIGN = 'flow.steptask.reassign',
  FLOW_STEPTASK_VIEW = 'flow.steptask.view',
  FLOW_WEBHOOK_SKIP = 'flow.webhook.skip',
  LIBRARY_ACCESS = 'library.access',
  PUBLICAPI_ACCESS = 'publicapi.access',
  REPORT_ACCESS = 'report.access',
  REPORT_BI_ACCESS = 'report.bi.access',
  SETUP_CATEGORY_ACCESS = 'setup.category.access',
  SETUP_DATABASE_ACCESS = 'setup.database.access',
  SETUP_FEATURE_UPDATE = 'setup.feature.update',
  SETUP_FEATURE_ADMIN = 'setup.feature.admin',
  SETUP_ROLE_ACCESS = 'setup.role.access',
  SETUP_ROLE_ADD = 'setup.role.add',
  SETUP_ROLE_DELETE = 'setup.role.delete',
  SETUP_ROLE_UPDATE = 'setup.role.update',
  SETUP_TEAM_ACCESS = 'setup.team.access',
  SETUP_USER_ACCESS = 'setup.user.access',
  SETUP_USER_UPDATE = 'setup.user.update',
  SUPPORT_ACCESS = 'support.access',
  TEMPLATE_ADMIN = 'template.admin',
  TENANT_ACCESS = 'tenant.access',
  TODO_ACCESS = 'todo.access',
  USER_CREATE = 'user.create',
  USER_ROLE_UPDATE = 'user.role.update',
  GOVERNANCE_ACCESS = 'governance.access',
  GOVERNANCE_FLOWMODEL_APPROVER_CANDIDATE = 'governance.flowmodelapprover.candidate',
  GOVERNANCE_FLOWMODEL_APPROVER_EDIT = 'governance.flowmodelapprover.edit'
}

export enum SearchEntityType {
  USER = 'User',
  GROUP = 'Group',
  APPROVER = 'Approver',
  STEP_FORM_FIELD = 'StepFormField',
  CUSTOM_EMAIL = 'CustomEmail'
}

export enum LineCoordinator {
  NONE = 'NONE',
  STANDARD = 'STANDARD',
  CUSTOMIZE = 'CUSTOMIZE'
}

export enum FlowModelType {
  WORKFLOW = 0,
  PROCESS_MAP = 1
}

export enum FlowModelPublishType {
  WORKFLOW = 0,
  PROCESS_MAP = 1,
  WORKFLOW_COMPONENT = 2,
  PROCESS_MAP_COMPONENT = 3
}

export enum CommentTargetType {
  FLOW = 1,
  FLOW_MODEL = 2,
  STEP_TASK = 3
}

export enum NotificationType {
  FLOW_COMMENT = 1,
  FLOW_MODEL_FEEDBACK = 2,
  STEP_TASK = 3
}

export enum TableCellType {
  TEXT = 1,
  CURRENCY = 2,
  NUMBER = 3,
  FILE = 4,
  DATE = 5,
  DROPDOWN = 6,
  LOOKUP = 7,
  FORMULA = 8
}

export enum TableColumnsName {
  FullName = 'fullName',
  TeamName = 'Team Name',
  Name = 'Name',
  Ppoc = 'Primary Point of Contact',
  PpocAlias = 'Ppoc',
  Teams = 'Teams',
  ManagerName = 'ManagerName',
  Users = 'Users'
}

export enum TableNoRowMessage {
  TABLE_NO_ROW = 'No rows were added'
}

export enum FormulaOperandPrefix {
  FIELD = 'field',
  CELL = 'column'
}

export enum ReassignType {
  Step = 'Step',
  StepTask = 'Step Task'
}

export enum LookupSpecialFieldLabels {
  CurrentActorEmailText = 'Logged In User Email',
  CurrentActorFullNameText = 'Logged In User FullName',
  InitiatorFullName = 'Initiator FullName',
  InitiatorEmail = 'Initiator Email'
}

export enum FormFieldConditionOption {
  Filled = 0,
  Empty = 1,
  Equals = 2,
  NotEquals = 3
}

export enum FormFieldConditionAction {
  Show = 0,
  Hide = 1
}

export enum FileFolder {
  Stepfiles = 'stepfiles',
  Templates = 'templates'
}

export enum DeleteEntityTypes {
  userEntityType = 1,
  teamEntityType = 2,
  databaseEntityType = 3,
  categoryEntityType = 4,
  flowModelEntityType = 5,
  roleEntityType = 6
}

export enum ActorTypes {
  USER = 'user',
  GROUP = 'group',
  DYNAMIC = 'dynamic'
}

export enum DynamicActorTypeNames {
  INITIATOR = 'Initiator',
  INITIATOR_MANAGER = 'InitiatorManager',
  INITIATOR_MANAGER_DISPLAY = 'Initiator Manager',
  PREVIOUS_ACTOR = 'PreviousActor',
  SELECT_DYNAMIC_ACTORS = 'SelectDynamicActors'
}

export enum DynamicActorTypeIds {
  ASSIGNED_INITIATOR = 1,
  ASSIGNED_INITIATOR_MANAGER = 2,
  ASSIGNED_PREVIOUS_ACTOR = 3,
  SELECT_DYNAMIC_ACTORS = 4
}

export enum LoginAuditIdentityProvider {
  UserAndPassword = 1,
  SSO = 2
}
export enum LoginAuditAction {
  Login = 1,
  Logout = 2
}

export enum CategoryDeleteWarningStatus {
  subCategoriesWithFlows = 0,
  reassignCategory = 1,
  canBeDeleted = 2
}

export enum StepTaskStatus {
  // Matches C# enum StepTaskStatus.
  InProgress = 0,
  WaitingForApproval = 1,
  Completed = 2,
  Cancel = 3
}

export enum StepIntegrationState {
  // Matches C# enum IntegrationState.
  Processing = 0,
  Completed = 1,
  Override = 2,
  Canceled = 3
}

export enum Redirect {
  LOCAL_STORAGE_KEY = 'flowingly.local.redirectRequest',
  QUERY_PARAM_KEY = 'redirectUri',
  FILE_PARAM_KEY = 'redirectFileId',
  FILE_STORAGE_KEY = 'flowingly.local.redirectFileRequest',
  OPEN_MODELER = 'openModeler',
  OPEN_FEEDBACK = 'openFeedback',
  OPEN_FILE = 'redirectFileId=',
  LOAD_PUBLISH_REQUEST_SCHEMA = 'loadPublishRequestSchema'
}

export enum WebhookTypes {
  Flowingly = 0,
  PowerAutomate
}

export enum LibraryDisplayTypes {
  DRAFT = 0,
  WORKFLOW = 1,
  PROCESS_MAP = 2
}

export enum LibraryTabRouteParams {
  DRAFTS = 'drafts',
  WORKFLOWS = 'workflows',
  PROCESS_MAPS = 'processmaps'
}

export enum ProcessMapsFilterTabs {
  ALL_MAPS_ID = 'all-maps',
  MAPS_IM_IN_ID = 'maps-im-in',
  RECENTLY_UPDATED_MAPS_ID = 'recently-updated-maps',
  MY_MAPS_ID = 'my-maps'
}

export enum FlowsToDoFilterTabs {
  TO_DO = 0,
  DUE_SOON = 1,
  DUE_TODAY = 2,
  OVERDUE = 3
}

export enum FlowsImInFilterTabs {
  STARTED = 0,
  DUE_SOON = 1,
  DUE_TODAY = 2,
  OVERDUE = 3,
  COMPLETED = 4,
  REJECTED = 5,
  IN_PROGRESS = 6,
  CANCELLED = 7
}
export enum FlowinglyEnvironments {
  Local = 'local'
}
export enum CustomDataTypeName {
  TEXT = 'text',
  CURRENCY = 'currency',
  NUMBER = 'number',
  EMAIL = 'email',
  USER = 'user'
}

export enum TimeUnit {
  Minutes = 'Minutes',
  Hours = 'Hours',
  Days = 'Days',
  None = 'None'
}

export enum GovernanceTabs {
  MY_APPROVALS = 'my-approvals',
  SENT_FOR_APPROVAL = 'sent-for-approvals',
  PROCESS_REVIEW_DUE = 'process-review-due'
}

const flowinglyConstants = {
  stepTypeName: StepTypeName,
  taskType: TaskType,
  nodeCategory: NodeCategory,
  approvalTaskOptions: ApprovalTaskOptions,
  formFieldType: FormFieldType,
  formFieldTypePascal: FormFieldTypePascal,
  formFieldTypeEnum: FormFieldTypeEnum,
  numberOfApproversRequiredType: NumberOfApproversRequiredType,
  whenApproversSelected: WhenApproversSelected,
  flowinglyRoles: FlowinglyRoles,
  permissions: FlowinglyPermissions,
  searchEntityType: SearchEntityType,
  EVERYONE_GROUP_NAME: 'Everyone',
  lineCoordinator: LineCoordinator,
  searchableComboPageSize: 100,
  maxNumberOfUsersToShow: 100,
  flowModelType: FlowModelType,
  flowModelPublishType: FlowModelPublishType,
  commentTargetType: CommentTargetType,
  notificationType: NotificationType,
  tableCellType: TableCellType,
  tableColumnsName: TableColumnsName,
  EMPTY_NAME: 'Please Choose...',
  minTextLengthToSearch: 1,
  emptyData: 'No Data Found',
  numericOperations: ['=', '!=', '<=', '>=', '<', '>'],
  tableNumberCalculationTypes: ['Sum'],
  tableNoRowMessage: TableNoRowMessage,
  formulaOperandPrefix: FormulaOperandPrefix,
  reassignType: ReassignType,
  formFieldConditionOption: FormFieldConditionOption,
  formFieldConditionAction: FormFieldConditionAction,
  fileFolder: FileFolder,
  stepTaskStatus: StepTaskStatus,
  stepTaskPriority: [
    {
      id: '0',
      name: 'Low'
    },
    {
      id: '1',
      name: 'Medium'
    },
    {
      id: '2',
      name: 'High'
    }
  ],
  stepIntegrationState: StepIntegrationState,
  redirect: Redirect,
  webhookTypes: WebhookTypes,
  libraryDisplayTypes: LibraryDisplayTypes,
  libraryTabRouteParams: LibraryTabRouteParams,
  modeler_RuleConditions: [
    {
      id: NodeRuleConditions.FieldReturnsAUserValue,
      name: 'If the below field returns a single User value'
    },
    {
      id: NodeRuleConditions.FieldReturnsATeamValue,
      name: 'If the below field returns a single Team value'
    }
  ],
  modeler_RuleEvents: [
    {
      id: 0,
      name: 'Then, change the Dynamic Actor of the Step below to the returned Actor'
    }
  ],
  modeler_WebhookAuthenticationDisplayTypes: [
    {
      id: 'None',
      name: 'None'
    },
    {
      id: 'Secret',
      name: 'Client Secret'
    },
    {
      id: 'Basic',
      name: 'Basic'
    }
  ],
  modeler_WebhookAuthenticationType: {
    NONE: 'None',
    SECRET: 'Secret',
    BASIC: 'Basic'
  },
  modeler_WebhookTriggerType: {
    // Matches Enum WebhookTrigger.
    STEP_COMPLETED: 'StepCompleted',
    STEP_CREATED: 'StepCreated'
  },
  modeler_WebhookTriggerTypeDisplayTypes: [
    {
      id: 'StepCompleted',
      name: 'When the step is completed',
      disabled: false
    },
    {
      id: 'StepCreated',
      name: 'When the step is created',
      disabled: false
    }
  ],
  dynamicActorTypeNames: DynamicActorTypeNames,
  flowinglyEnvironments: FlowinglyEnvironments,
  customDataTypeName: CustomDataTypeName,
  timeUnit: TimeUnit
};

angular
  .module('flowingly.services')
  .constant('flowinglyConstants', flowinglyConstants);

export type FlowinglyConstantsType = typeof flowinglyConstants;
