import { ICommentMention } from './comment-mention.interface';

export interface IComment {
  fullName?: string;
  avatarUrl?: string;
  userId: string;
  commentedByName?: string;
  momentCommented?: string;
  comment: string;
  createdDate?: string;
  mentions?: ICommentMention[];
  flowOwnerOnly?: boolean;
  files?: any[];
  fileIds?: string[];
  commentType: CommentType;
  flowModelVersion: string;
}

export enum CommentType {
  // User entered comment.
  UserComment = 0,

  // Step task system comment.
  StepTaskCreated = 1,
  StepTaskDone = 2,
  StepTaskApproved = 3,
  StepTaskRejected = 4
}
